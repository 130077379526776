<template>
    <div class="mx-auto container container--fluid">
        <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-app id="inspire" class="">
            <template v-if="load==true">
                <v-data-table :headers="headers" :items="placement_year_config_list" class="elevation-1" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-toolbar-title>Placement Year Configuration</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
                            </v-text-field>
                            <span style="width: 20px"></span>
                            <v-dialog v-model="dialog" max-width="500px">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="indigo darken-4" dark class="mb-2" v-on="on" @click="iseditable=!iseditable">New Placement Year Configuration </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12" v-if='editedIndex == -1'>
                                                    <v-autocomplete :items="program_list" v-model="editedItem.programtype" label="Select Program Type *" :rules="[v => !!v || 'required']"></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" v-if='editedIndex != -1'>Organization
                                                    <v-select dense v-model="editedItem.programtype" :items="program_list" item-value="id" persistent-hint  :rules="[v => !!v || 'required']"></v-select>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" v-if='editedIndex == -1'>
                                                    <v-autocomplete :items="year_list" v-model="editedItem.year" label="Select Year *" :rules="[v => !!v || 'required']"></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" v-if='editedIndex != -1'>Year
                                                    <v-select dense v-model="editedItem.year" :items="year_list" item-value="id" persistent-hint  :rules="[v => !!v || 'required']"></v-select>
                                                </v-col>
                                                
                                                <v-col cols="12" sm="12" md="12" v-if='editedIndex == -1'>
                                                    <v-autocomplete :items="org_list" v-model="editedItem.organization" label="Select Organization *" :rules="[v => !!v || 'required']"></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" v-if='editedIndex != -1'>Organization
                                                    <v-select dense v-model="editedItem.organization" :items="org_list" item-value="id" persistent-hint  :rules="[v => !!v || 'required']"></v-select>
                                                </v-col>

                                                

                                            
                                                <v-col>
                                                    <input type="hidden" v-model="editedItem.id" name="id" />
                                                    <!-- <v-hidden v-model="editedItem.id" label="id"></v-hidden> -->
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6" sm="3" md="2">
                                                    <v-checkbox v-model="editedItem.isactive" color="success" label="isActive?"></v-checkbox>
                                                </v-col>
                                            </v-row> 

                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                        <v-btn color="primary darken-1" @click="save">Save</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <!--<template v-slot:item.isactive="{ item }">
                        <span v-if="item.isactive">
                        <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else>
                        <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                        </span>
                    </template>-->
                    <template v-slot:item.isactive="{ item }">
       
                        <v-switch v-model="item.isactive" @click="activeswitch(item)"></v-switch>
                    </template>
                    
                    <template v-slot:item.action="{ item }">
                        <div>
                            <v-avatar style="" size="25" class="edit-avatar">
                                <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
                            </v-avatar>
                        </div>
                    </template>
                </v-data-table>
            </template>
        </v-app>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data: () => ({
            overlay: false,
            snackbar_msg: "",
            color: "",
            snackbar: false,
            iseditable: false,
            load: false,
            dialog: false,
            placement_year_config_list: null,
            org_list: null,
            program_list: null,
            year_list:'',
            search: '',
            headers: [  
            {
                text :"Program Type",
                value:'programtype'
            },
            {
                text:"Year",
                value:'year'
            },
            {
                text: "Organization",
                value: "organization"
            },
           
            {
                text: "Status",
                value: "isactive"
            },  
            {
                text: "Actions",
                value: "action",
                sortable: false
            }],

            editedIndex: -1,
            editedItem: {
                id: 0,
                year: '',
                organization: '',
                programtype:'',
                 isactive: true,
            },
            defaultItem: {
                id: 0,
                year: '',
                organization: '',
                programtype:'',
                isactive: true,
            }
        }),

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Placement Year Configuration" : "Edit Placement Year Configuration";
            }
        },

        watch: {
            dialog(val) {
                    val || this.close();
                },
                overlay(val) {
                    val = () => {
                        this.overlay = false
                    };
                },
        },
        mounted() {
            this.onload()
            
        },

        methods: {
            onload(){
                axios
                .post("/PlacementYearConfiguration/addPlacementConfig")
                .then(res => {
                    if (res.data.msg == "200") {
                        this.placement_year_config_list = res.data.placement_year_config_list
                        this.org_list = res.data.org_list
                        this.program_list = res.data.program_list
                        this.year_list = res.data.year_list
                        this.load = true;
                    } else {
                                // window.console.log(res.data.msg)
                            }
                })
                .catch(error => {
                            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                            // window.console.log(error);
                        })
                        .finally(() => {
                            // 'use strict';
                            var overlay = false;
                        });
            },
            editItem(item) {
                    this.iseditable = false;
                    this.editedIndex = this.placement_year_config_list.indexOf(item);
                    this.editedItem = Object.assign({}, item);
                    this.dialog = true;
                },
                showSnackbar(clr, msg) {
                    this.snackbar = true;
                    this.color = clr;
                    this.snackbar_msg = msg;
                },
                close() {
                    this.dialog = false;
                    setTimeout(() => {
                        this.editedItem = Object.assign({}, this.defaultItem);
                        this.editedIndex = -1;
                    }, 300);
                },

                save() {
                    if (this.editedItem.programtype == "") {
                        this.showSnackbar("red", "please Select Program Type");
                    } else if (this.editedItem.year == "") {
                        this.showSnackbar("red", "please Select Year");
                    }
                    else if (this.editedItem.organization == "") {
                        this.showSnackbar("red", "please Select Organization");
                    }  
                    else{
                        if (this.editedIndex > -1) {
                            //  console.log("edit.." + this.editedItem);
                            this.iseditable = false;
                            axios
                                .post("/PlacementYearConfiguration/editPlacementConfig", this.editedItem)
                                .then(res => {
                                    if (res.data.status.code == "SUCCESS") {
                                        Object.assign(this.placement_year_config_list[this.editedIndex], this.editedItem);
                                        this.onload()
                                    this.showSnackbar(
                                        "#4caf50",
                                        "Placement Year Configuration Updated Successfully..."
                                    ); // show snackbar on success
                                    } else if (res.data.status.code == "NA") {
                                        //alert(res.data.status.message)
                                        this.showSnackbar("#b71c1c", "Placement Year Configuration Already Present!!!"); // show snackbar on error
                                    }
                                })
                                .catch(error => {
                                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                                    // window.console.log(error);
                                });
                        } else {
                            axios
                                .post("/PlacementYearConfiguration/savePlacementConfig", this.editedItem)
                                .then(res => {
                                    if (res.data.status.code == "SUCCESS") {
                                        this.placement_year_config_list.push(this.editedItem);
                                        this.onload()
                                        this.showSnackbar("#4caf50", "New Placement Year Configuration Added Successfully!!!"); // show snackbar on success
                                    } else if (res.data.status.code == "NA") {
                                        //alert(res.data.status.message)
                                        this.showSnackbar("#b71c1c", "Placement Year Configuration Already Present!!!"); // show snackbar on error
                                    }
                                })
                                .catch(error => {
                                    window.console.log(error);
                                });
                        }
                        this.close();
                    }    
                },
                 activeswitch(item) {
            // console.log("abc");

            const data = {
                deleteitem: item.id,
            };
            // console.log(item.id);

            axios
                .post("/PlacementYearConfiguration/activePlacementYearConfig", data)
                .then((res) => {
                // console.log("res.data");
                // console.log(res.data);
                if (res.data.msg == "200") {
                    
                    this.showSnackbar("#4caf50", res.data.message);
                    this.onload();
                } else {
                    
                    this.showSnackbar("#b71c1c", res.data.msg);
                }
                })
                .catch((error) => {
                window.console.log(error);
                });
            this.close();
            },
        }
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    
    .v-icon-size {
        font-size: 20px;
    }
    
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    
    .edit-v-icon:hover {
        color: white;
    }
</style>